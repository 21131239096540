export const executeModule = async (modulePromise) => {
    try {
        const mod = await modulePromise;
        if (mod?.default) {
            return await mod.default();
        }
    } catch (error) {
        console.error(error);
    }
};

export const loadModules = (modules) => modules.filter(Boolean);

export const importIf = (callback, dependencies) =>
    (Array.isArray(dependencies)
        ? dependencies.every(Boolean)
        : dependencies) && callback();
