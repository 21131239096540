let cachedDevice;
export const getDevice = () => {
    if (!cachedDevice) {
        cachedDevice = document.documentElement.dataset.device || 'mobile';
    }

    return cachedDevice;
};

export const getAdvertoryDevice = () => {
    const device = document.documentElement.dataset.device || 'mobile';

    if (device.includes('mobile')) {
        return 'mobile';
    }

    if (device.includes('tablet')) {
        return 'tablet';
    }

    return 'desktop';
};

export const isVGApp = () => getDevice().endsWith('-app');

export const isMobile = () => getDevice().startsWith('mobile');

export const isDesktop = () => getDevice() === 'desktop';

export const isTablet = () => getDevice().startsWith('tablet');

export const getOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('ios') || userAgent.includes('ipad')
        ? 'ios'
        : 'android';
};

export const supportHover = () => matchMedia('(hover: hover)').matches;

export const isSafari = () => navigator.vendor.match(/apple/i);

export const isIOS = () =>
    document.documentElement.dataset.deviceIsIos === 'true';

export const isFirefox = () => navigator.userAgent.match(/firefox/i);

export const supportCssFeature = (prop, value) =>
    window.CSS && window.CSS.supports(prop, value);

export const isIpad = () =>
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);

export const isChrome = () =>
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const supportNativeImageLazyload = () =>
    'loading' in HTMLImageElement.prototype;

export const supportNativeIFrameLazyload = () =>
    'loading' in HTMLIFrameElement.prototype;
