import { getScript, deferOnload } from '../utils/';

export default async function trackPageView() {
    const ENABLED_TRACKERS = [
        'mbl-linkpulse',
        'brandmetrics',
        'braze',
        'norstat-ppid-pixel',
    ];

    const trackers = [
        import('./pulse'),
        ...ENABLED_TRACKERS.map(
            (tracker) =>
                import(/* webpackMode: "eager" */ `./thirdparty/${tracker}`),
        ),
    ];

    trackers.map(async (trackerMod) => {
        try {
            const { default: tracker } = await trackerMod;
            if (tracker.waitForConsent) await tracker.waitForConsent();

            await tracker.trackPageView({
                getScript,
                deferOnload,
            });
        } catch (err) {
            console.warn(err);
        }
    });
}
