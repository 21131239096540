const PARAMS_TO_REMOVE = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
];

/**
 * @param {string} url
 */
export const removeUtmParams = (url) => {
    const urlObj = new URL(url);
    PARAMS_TO_REMOVE.forEach((param) => {
        urlObj.searchParams.delete(param);
    });
    return urlObj.pathname + urlObj.search;
};

export const getUtmParams = () => {
    const params = {};
    const currentUrl = new URL(window.location.href);
    PARAMS_TO_REMOVE.forEach((param) => {
        const value = currentUrl.searchParams.get(param);
        if (value) {
            params[param.replace('utm_', '')] = value;
        }
    });
    return params;
};

/**
 * @param {string} url
 */
export const updateHistoryState = (url) => {
    window.history.replaceState(
        {
            utmTags: getUtmParams(),
        },
        '',
        url,
    );
};
