import { getConsentedToAll, subscribeToConsentedToAll } from '@vgno/utils';

/**
 * Get eids from apntag
 * @returns {Promise<Array>} - eids
 */
function getEids() {
    return new Promise((resolve) => {
        const getStoredIds = () =>
            window?.apntag?.requests?.user?.userIds?.[0]?.eids;
        const eids = getStoredIds();
        if (eids) {
            resolve(getStoredIds());
        } else {
            window.apntag.onEvent('adRequested', () => {
                resolve(getStoredIds());
            });
        }
    });
}

/**
 * construct userEnvHash and userHash string for the norstat pixel
 * @returns string - userEnvHash
 */
const getEidUrlPath = async () => {
    const eids = await getEids();
    if (!eids) {
        return;
    }

    const ppid1 = eids.find((eid) => eid.source === 'SCHNO-UserHash');
    const ppid2 = eids.find((eid) => eid.source === 'SCHNO-EnvHash');

    let userEnvHash = '';
    if (ppid2) {
        userEnvHash += `SCHNO-Envhash-${ppid2.id}`;
    }

    if (ppid1) {
        userEnvHash += `?alias=SCHNO-Userhash-${ppid1.id}`;
    }

    return userEnvHash;
};

const waitForConsent = () => {
    return new Promise((resolve) => {
        getConsentedToAll().then((consent) => {
            if (consent) {
                resolve();
                return;
            }

            subscribeToConsentedToAll((newConsent) => {
                if (newConsent) resolve();
            });
        });
    });
};

/**
 * Track page view
 * @param {Object} options
 * @param {Function} options.deferOnload - defer onload
 * @returns {Promise<void>}
 */
export async function trackPageView({ deferOnload }) {
    const norstatPpidPixel = document.getElementById('norstat-ppid-pixel');
    if (norstatPpidPixel) {
        return;
    }

    await deferOnload();
    const eid = await getEidUrlPath();
    if (!eid) {
        return;
    }

    const url = `https://sch-map.norstatsurveys.com/api/nids/schibsted/map/${eid}`;

    const img = document.createElement('img');
    img.src = url;
    img.id = 'norstat-ppid-pixel';
    img.style.display = 'none';
    document.body.appendChild(img);
}

export default {
    waitForConsent,
    trackPageView,
};
