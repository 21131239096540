export const importShim = () => {
    window.importShim = window.importShim
        ? window.importShim
        : (url) => {
              try {
                  return import(/* webpackIgnore: true */ url);
              } catch {
                  console.warn('Failed to import', url);
                  return Promise.resolve();
              }
          };
};
