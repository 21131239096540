/**
 * This modules run view and click-tracking on all article-elements on the front-page
 */

import { onView } from './utils/trackVisibility';
import { trackEngagement } from './tracking/pulse';
import { debounce } from 'lodash-es';

// Constants
const TRACKING_ID = 'FrontTracking';
const SELECTOR_ELEMENT =
    'article.article, .partnerstudio-front .container, [data-enable-front-tracking]'; /* + SELECTOR_NOT_ELEMENT*/
const BRAND_MAP = {
    'vg.no': 'vg',
    'direkte.vg.no': 'direkte-vg',
    'minmote.no': 'minmote',
    'vgtv.no': 'vgtv',
    'tv.vg.no': 'vgtv',
    'dinepenger.no': 'dinepenger',
    'tek.no': 'tekno',
    'godt.no': 'godt',
    'vektklubb.no': 'vektklubb',
    'vglive.no': 'vglive',
    'vglive.vg.no': 'vglive',
    'e24.no': 'e24',
};

const VGLAB_TAGS_MAP = {
    'https://penger.no/boliglan': 'vertical-pengerno-mortgage',
    'https://penger.no/kredittkort': 'vertical-pengerno-creditcard',
    'https://penger.no/sparekonto': 'vertical-pengerno-savings',
    'https://penger.no/strom': 'vertical-pengerno-electricity',
    'https://penger.no/mobilabonnement': 'vertical-pengerno-mobile',
    'https://penger.no/boligverdi': 'vertical-pengerno-homevalue',
    'https://penger.no/billan': 'vertical-pengerno-carloan',
};

const findVGLabTag = (url) => {
    if (!url) {
        return;
    }
    return VGLAB_TAGS_MAP[
        Object.keys(VGLAB_TAGS_MAP).find((key) => url.startsWith(key))
    ];
};

const getVGLabTrackingData = (element) => {
    const tag = findVGLabTag(element?.querySelector('a')?.href);
    if (!tag) {
        return;
    }
    return tag;
};

// Queues
let elementQueue = [];

// Global variables
const startTime = Date.now();
/** @type {IntersectionObserver} */
let FRONT_TRACKING_TEASER_OBSERVER;

/**
 * Gets current scroll-position
 * FIXME: Use data from trackvisibility helper
 */
const getScrollPosition = () =>
    Math.floor(
        (window.pageYOffset || document.scrollTop || 0) -
            (document.clientTop || 0),
    );

const mapScrollPosition = (elements, scrollPosition) =>
    elements.map((element) => ({
        ...element,
        scrollPosition,
    }));

const elementTrackingData = new WeakMap();
export const getElementTrackingData = (elm) => {
    if (!elementTrackingData.has(elm)) {
        elementTrackingData.set(
            elm,
            elm.trackingData ??
                (elm.querySelector('.tracking-data') &&
                    JSON.parse(
                        elm.querySelector('.tracking-data').textContent,
                    )),
        );
    }

    return elementTrackingData.get(elm);
};

/**
 * Extract type of front-item to be sent with the pulse-events.
 * @param String url
 */
const VIDEO_HOSTS = ['vgtv.no', 'tv.vg.no'];
function getTargetTypeFromLink(link) {
    const { hostname, pathname } = link;
    if (VIDEO_HOSTS.find((host) => hostname.endsWith(host))) {
        return 'mediaasset';
    }

    if (pathname.startsWith('/stories/')) {
        return 'story';
    }

    if (hostname == 'direkte.vg.no') {
        return pathname.includes('/videos/')
            ? 'mediaasset'
            : pathname.includes('/news/')
              ? 'live-entry'
              : 'studio';
    }

    return 'article';
}

const getBrand = (brand) => BRAND_MAP[brand] || brand || 'vg';

/**
 * Return TargetData for element.
 * @param Node element
 */
function getTargetData(element) {
    const trackingData = getElementTrackingData(element);
    if (!trackingData) {
        return;
    }

    const link = element.matches('a') ? element : element.querySelector('a');
    if (!link) {
        return;
    }

    let targetId = trackingData.articleId;
    if (!targetId) {
        if (link.pathname.includes('/spesial/')) {
            targetId = 'spesial';
        } else {
            targetId = 'noid';
        }
    }

    const targetType = getTargetTypeFromLink(link);
    const brand = getBrand(trackingData.brand);
    let id = `sdrn:${brand}:${targetType}:${targetId}`;
    let custom;

    // custom id for direkte teasers
    if (brand == 'direkte-vg') {
        const [studio] = link.pathname.split('/').slice(1);
        if (studio) {
            if (targetType == 'studio') {
                id = id.replace('noid', studio);
            } else {
                custom = { studio };
            }
        }
    }

    // custom id for spesial teasers
    if (id.includes('sdrn:vg:article:/spesial/')) {
        const specialIdArray = id.split('/').slice(1).filter(Boolean);
        if (specialIdArray.length > 0) {
            // id for standard specials
            id = `sdrn:vg:article:${specialIdArray.join('-')}`;

            // id for specials with subpages
            if (specialIdArray.length > 3) {
                id = `sdrn:vg:article:${specialIdArray
                    .slice(0, 3)
                    .join('-')}:${specialIdArray.slice(3, 10).join('-')}`;
            }
        }
    }

    return {
        '@id': id,
        '@type': targetType,
        name: '',
        url: link.href,
        'spt:custom': custom,
    };
}

/**
 * Get metadata for an element
 * @param Node element
 */
function getElementData(element, scrollPosition) {
    const trackingData = getElementTrackingData(element);
    if (!trackingData) {
        return;
    }

    const {
        drFrontId = '',
        drFrontRow,
        row = 0,
        position,
        skin = 'default', // TOOD: Rename skin -> theme | skinIcon -> icon
        skinIcon = '',
        size = 0,
        teaserText = '',
        section,
        ...rest
    } = trackingData;

    delete rest.articleId;
    delete rest.brand;
    delete rest.changes;
    delete rest.wordCount;

    const id = 'sdrn:vg:frontpage:front:element:' + drFrontId;
    let tags = [];
    const vgLabTag = getVGLabTrackingData(element);
    if (vgLabTag) {
        tags.push(vgLabTag);
    }

    return {
        ...rest,
        '@id': id,
        duration: 0,
        drFrontRow,
        row,
        position,
        skin,
        tags,
        skinIcon: skinIcon ?? '',
        section,
        sampleSize: 100, // This will always be 100
        size,
        teaserText,
        scrollPosition,
    };
}

/**
 * Parse a viewed element and store the data in the elementQueue
 * @param Node element
 */
function parseViewedElement(element) {
    const elementData = getElementData(element);
    if (!elementData) {
        return;
    }

    const targetData = getTargetData(element);
    if (!targetData) {
        return;
    }

    elementData.target = targetData;

    // Save to queue
    elementQueue.push(elementData);

    sendViewEvents();
}

/**
 *
 * @param String targetId - Id for target.
 * @param Array elements - Array containing the elements views since last view-event.
 */
function trackView(elementId, elements) {
    const currentTime = Date.now();
    const duration = Math.floor((currentTime - startTime) / 1000);
    const scrollPosition = getScrollPosition();

    trackEngagement({
        action: 'Scroll',
        elementId,
        elementType: TRACKING_ID,
        elements: mapScrollPosition(elements, scrollPosition),
        duration,
        scrollPosition,
        intent: 'View',
    });
}

/**
 * Send pulse-event for elements that has been clicked on.
 * @param String targetId - Id of element that was clicked.
 * @param Object customData - Custom-data for the element (event.object)
 * @param Object targetData - Metadata about the element.
 * @param Object elements - Elements that has been clicked om.
 * @param Function callback - Trigger callback when pulse-event successfully has been sent.
 */
function trackClick(elementId, customData, targetData, elements, event) {
    const currentTime = Date.now();
    const duration = Math.floor((currentTime - startTime) / 1000);
    const scrollPosition = getScrollPosition();

    trackEngagement({
        action: 'Click',
        elementId,
        elementType: TRACKING_ID,
        objectExtra: customData,
        elements: mapScrollPosition(elements, scrollPosition),
        target: targetData,
        duration,
        scrollPosition,
        intent: 'Open',
        event,
    });
}

/**
 * Empty elementQueue and send them with a view-event.
 */
const sendViewEvents = debounce(
    function _sendViewEvents() {
        if (elementQueue.length === 0) {
            return;
        }

        const elements = elementQueue.splice(0);
        trackView(TRACKING_ID, elements);
    },
    500,
    { maxWait: 1000 },
);

/**
 * Initiates view-tracking.
 * @param {HTMLElement[]} elementsToTrack
 */
function initViewTracking(elementsToTrack) {
    FRONT_TRACKING_TEASER_OBSERVER = onView(
        elementsToTrack,
        ({ element }) => {
            parseViewedElement(element);
        },
        {
            threshold: 0.7,
        },
    );
}

function onClick(event) {
    if (event.button == 2) {
        return;
    }
    const { target } = event;
    const linkElm = target.closest('a');
    const article = linkElm?.closest(SELECTOR_ELEMENT);

    if (linkElm && article && getElementTrackingData(article)) {
        // Elements
        const elements = elementQueue.splice(0);

        // Custom-data
        const customData = getElementData(article);

        if (customData) {
            const objectId = customData['@id'];

            // Target-data
            const targetData = getTargetData(article);

            // Send click-event
            trackClick(objectId, customData, targetData, elements, event);
        }
    }
}

/**
 * Initiates click-tracking
 */
function initClickTracking() {
    document.addEventListener('click', onClick);
    document.addEventListener('auxclick', onClick);
}

/**
 * Initiates front-tracking.
 */
export function init() {
    const elementsToTrack = $$(SELECTOR_ELEMENT).filter(
        (elm) =>
            !!elm.querySelector('a') && !!elm.querySelector('.tracking-data'),
    );

    initViewTracking(elementsToTrack);
    initClickTracking();
}

/**
 * @param {Element} elm
 * @void
 */
export function trackTeaser(elm) {
    if (FRONT_TRACKING_TEASER_OBSERVER) {
        FRONT_TRACKING_TEASER_OBSERVER.observe(elm);
    } else {
        console.error(new Error('Failed to track teaser-element.'));
    }
}

export default () => init();
