import { getConsentedToAll, subscribeToConsentedToAll } from '@vgno/utils';
import { importOnElement } from '../../utils';

const SCRIPT_URL =
    'https://cdn.brandmetrics.com/survey/script/958329b0909545fe8f0cdc6dc8ba49d0.js';

const waitForConsent = () => {
    return new Promise((resolve) => {
        getConsentedToAll().then((consent) => {
            if (consent) {
                resolve();
                return;
            }

            subscribeToConsentedToAll((newConsent) => {
                if (newConsent) resolve();
            });
        });
    });
};

async function trackPageView({ getScript, deferOnload }) {
    await deferOnload();

    importOnElement(() => {
        window._brandmetrics = window._brandmetrics || [];
        window._brandmetrics.push({ cmd: '_loadsurvey' });

        getScript(SCRIPT_URL);
    }, '#brandmetrics-survey');

    return true;
}

export default {
    waitForConsent,
    trackPageView,
};
