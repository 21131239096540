import { trackEngagement } from './tracking/pulse';
import { trackTeaser } from './front-tracking';
import * as device from './utils/device';
import { isPreview } from './utils/routes';
import { getUnleashClient } from './utils/unleash.js';

/**
 * @param {string} href
 */
function loadStylesheet(href) {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';

    document.head.append(link);
}

/** @param {HTMLElement} target */
async function loadWidget(target) {
    const { dataset } = target;
    const props = JSON.parse(dataset.props || '{}');

    if (dataset.stylesheet) {
        loadStylesheet(target.dataset.stylesheet);
    }

    const module = await import(/* webpackIgnore: true */ dataset.src).then(
        (mod) => mod.default,
    );

    module({
        ...dataset,
        node: target,
        trackEngagement,
        trackTeaser,
        device,
        isPreview,
        props,
        getUnleashClient,
    });
}

class DynamicWidget extends HTMLElement {
    _observer = null;
    constructor() {
        super();
    }

    connectedCallback() {
        if (
            this.dataset.preload === 'true' ||
            this.dataset.initiallyHidden === 'true'
        ) {
            loadWidget(this);
            return;
        }

        this._observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const { target } = entry;
                        if (target instanceof HTMLElement) {
                            target.classList.add('dynamic-widget-loaded'),
                                this._observer.disconnect();
                            loadWidget(target);
                        }
                    }
                });
            },
            {
                rootMargin: '100% 0px',
            },
        );

        this._observer.observe(this);
    }

    disconnectedCallback() {
        if (this._observer) {
            this._observer.disconnect();
        }
    }
}

export default () => {
    customElements.define('vg-dynamic-widget', DynamicWidget);
};
